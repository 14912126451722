<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>大数据管理</el-breadcrumb-item>
            <el-breadcrumb-item>上饶人才大数据</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="topchange">
                <el-input size="small" v-model="num" placeholder="请输入一个数字" style="width:200px;margin-right:5px;"  onkeyup="value=value.replace(/[^\d\.]/g,'')" ></el-input><el-button type="primary" size="small" @click="changedata">更改数据</el-button><el-button type="primary" size="small" @click="resetdata">重置数据</el-button>
            </div>
            <div class="list" >
                <div class="listtitle"><strong>园区平均薪资水平统计</strong><el-button type="primary" size="mini" style="float:right;" @click="openadd(1)">+添加记录</el-button></div>
                <div class="listitem" v-loading="loading">
                    <ul>
                        <li v-for="(item,index) in list"><span>{{item.bcName}}</span><em>{{item.bcValue}}</em><p style="margin-top:0;"><i @click="editopen(item)">编辑</i></p></li>
                    </ul>
                </div>
            </div>
            <div class="list" >
                <div class="listtitle"><strong>统计意向达成人数</strong><el-button type="primary" size="mini" style="float:right;" @click="openadd(2)">+添加记录</el-button></div>
                <div class="listitem" v-loading="loading2">
                    <ul>
                        <li v-for="(item,index) in list2"><span>{{item.bcName}}</span><em>{{item.bcValue}}</em><p style="margin-top:0;"><i @click="editopen(item)">编辑</i></p></li>
                    </ul>
                </div>
            </div>
            <div class="topchange" style="margin-top:50px;">
                <el-input size="small" v-model="num2" placeholder="请输入一个数字" style="width:200px;margin-right:5px;"  onkeyup="value=value.replace(/[^\d\.]/g,'')" ></el-input><el-button type="primary" size="small" @click="changedata2">更改数据</el-button><el-button type="primary" size="small" @click="resetdata2">重置数据</el-button>
            </div>
             <div class="listtitle" style="margin-top:10px;"><strong>园区重点企业招聘情况统计</strong></div>
             <div class="cont">
                紧缺岗位人数:
                <el-input size="small" v-for="(item,index) in list3[0].bcValue" :key="index" v-model="list3[0].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
            </div>
            <div class="cont">
                活跃求职者人数:
                <el-input size="small" v-for="(item,index) in list3[1].bcValue" :key="index" v-model="list3[1].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
            </div>
            <div class="listtitle" style="margin-top:10px;"><strong>每日数据流量统计</strong></div>
            <div class="cont">
                APP流量:
                <el-input size="small" v-for="(item,index) in list3[2].bcValue" :key="index" v-model="list3[2].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
            </div>
            <div class="cont">
                微信公众号流量:
                <el-input size="small" v-for="(item,index) in list3[3].bcValue" :key="index" v-model="list3[3].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
            </div>
            <div class="cont">
                官网流量:
                <el-input size="small" v-for="(item,index) in list3[4].bcValue" :key="index" v-model="list3[4].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
            </div>
            <div class="cont">
                线下数据:
                <el-input size="small" v-for="(item,index) in list3[5].bcValue" :key="index" v-model="list3[5].bcValue[index]" placeholder="" style="width:100px; margin-right:6px;"></el-input>
            </div>
            <div style="text-align:center; padding:20px 0;">
                <el-button type="primary" @click="updatedown">提交保存</el-button>
            </div>
        </div>
        <pop-up title="添加" width="26%" :isshow="addshow" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="addfrom" :model="addfrom" label-width="40px">
                    <el-form-item label="名称">
                        <el-input v-model="addfrom.bcName"></el-input>
                    </el-form-item>
                    <el-form-item label="值">
                        <el-input v-model="addfrom.bcValue"></el-input>
                    </el-form-item>
                    <el-form-item label="排序">
                        <el-input v-model="addfrom.sort"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
        <pop-up title="编辑" width="26%" :isshow="editshow" :haveconfirm="true" @close="getclose2" @confirm="getconfirm2">
            <div slot="box">
                <el-form ref="addfrom" :model="editfrom" label-width="40px">
                    <el-form-item label="名称">
                        <el-input v-model="editfrom.bcName"></el-input>
                    </el-form-item>
                    <el-form-item label="值">
                        <el-input v-model="editfrom.bcValue"></el-input>
                    </el-form-item>
                     <el-form-item label="排序">
                        <el-input v-model="editfrom.sort"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import serverurl from '@/api/bigdata'
import _api from '@/api/index'
export default {
    data(){
        return{
            num:"",
            num2:"",
            list:[],
            list2:[],
            loading:false,
            loading2:false,
            addshow:false,
            editshow:false,
            addfrom:{
                type:"",
                bcName:"",
                bcValue:"",
                sort:1
            },
            editfrom:{
                id:"",
                bcName:"",
                bcValue:"",
                sort:0
            },
            list3:[
                {version:1,isDelete:false,type:1,bcName:"紧缺岗位人数",bcValue:[0,0,0,0,0,0,0]},
                {version:1,isDelete:false,type:1,bcName:"活跃求职者人数",bcValue:[0,0,0,0,0,0,0]},
                {version:1,isDelete:false,type:2,bcName:"APP流量",bcValue:[0,0,0,0,0,0,0]},
                {version:1,isDelete:false,type:2,bcName:"微信公众号流量",bcValue:[0,0,0,0,0,0,0]},
                {version:1,isDelete:false,type:2,bcName:"官网流量",bcValue:[0,0,0,0,0,0,0]},
                {version:1,isDelete:false,type:2,bcName:"线下数据",bcValue:[0,0,0,0,0,0,0]},
            ],
            newarr:[]
        }
    },
    created(){
        this.getList()
        this.getList2()
        this.getList3()
    },
    methods:{
        updatedown(){
                this.newarr=JSON.parse(JSON.stringify(this.list3))

                for(let item of this.newarr){
                    item.bcValue=item.bcValue.join(",")
                }
                _api.post(serverurl.dailyDatainsert,{dailyData:this.newarr}).then(res=>{
                    console.log(res)
                    if(res.success){
                        this.$message.success("更新成功！")
                        this.getList3()
                    }
                })
        },
        getList3(){
            _api.get(serverurl.dailyData,{}).then(res=>{
                console.log(res)
                if(res.success){
                    this.list3=res.data
                    for(let item of this.list3){
                        item.bcValue=item.bcValue.split(",")
                    }
                }
            })
        },
        resetdata(){
            _api.get(serverurl.averagereset,{}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("重置成功！")
                    this.getList()
                    this.getList2()
                }
            })
        },
        resetdata2(){
            _api.get(serverurl.dailyDatareset,{}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("重置成功！")
                    this.getList3()
                }
            })
        },
        changedata2(){//更改数据
            if(this.num2==""){
                this.$message.error("请输入一个变换系数")
                return
            }
            _api.get(serverurl.dailyDatarandom,{num:this.num2}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("更改成功！")
                    this.getList3()
                }
            })
        },
        changedata(){//更改数据
            if(this.num==""){
                this.$message.error("请输入一个变换系数")
                return
            }
            _api.get(serverurl.averagerandom,{num:this.num}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("更改成功！")
                    this.getList()
                    this.getList2()
                }
            })
        },
        deleteitem(id){
            _api.get(serverurl.deleteitem,{id:id}).then(res=>{
                if(res.success){
                    this.$message.success("删除成功！")
                    this.getList()
                }
            })
        },
        editopen(row){
            this.editshow=true
            this.editfrom.id=row.id
            this.editfrom.bcName=row.bcName
            this.editfrom.bcValue=row.bcValue
            this.editfrom.sort=row.sort
        },
        getList(){
            this.loading=true
            _api.get(serverurl.average,{type:1}).then(res=>{
                console.log(res)
                this.loading=false
                this.list=res.data
            })
        },
        getList2(){
            this.loading2=true
            _api.get(serverurl.average,{type:2}).then(res=>{
                console.log(res)
                this.loading2=false
                this.list2=res.data
            })
        },
        openadd(e){
            if(e==1 && this.list.length>=7){
                this.$message.error("最多只能添加7条数据！")
                return
            }
            if(e==2 && this.list2.length>=7){
                this.$message.error("最多只能添加7条数据！")
                return
            }
            this.addshow=true
            this.addfrom.type=e
            
        },
        getclose(){
            this.addshow=false
        },
        getconfirm(){
            
            _api.post(serverurl.averageinsert,this.addfrom).then(res=>{
                if(res.success){
                    this.addshow=false
                    if(this.addfrom.type==1){
                        this.getList()
                    }else{
                        this.getList2()
                    }
                    
                    this.$message.success("添加成功！")
                    this.addfrom.type=""
                    this.addfrom.bcName=""
                    this.addfrom.bcValue=""
                    this.addfrom.sort=1
                }
            })
        },
        getclose2(){
            this.editshow=false
        },
        getconfirm2(){
            _api.post(serverurl.averageinsert,this.editfrom).then(res=>{
                if(res.success){
                    this.editshow=false
                    this.$message.success("编辑成功！")
                    this.getList()
                    this.editfrom.id=""
                    this.editfrom.bcName=""
                    this.editfrom.bcValue=""
                    this.editfrom.sort=0
                }
            })
        }
    }
}
</script>
<style scoped>
@import "../../assets/css/bigdata.css";
.cont{ padding:12px 0;}
.topchange{ text-align: center; padding-bottom: 20px;}
</style>